
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
   meta: {
    de: {
      title: 'Karriere | Styria Media Group',
      keywords: ['marketplaces','karriere','jobs','stellenausschreibung'],
      description: 'Offene Stellen in der STYRIA. Starten Sie Ihre Karriere bei willhaben, Njuškalo.hr, bolha.com oder MojeDelo.com. ',
      image: require('@/assets/img/og/brands.png'),
      
    },
    en: {
      title: 'career | Styria Media Group',
     keywords: ['marketplaces','career','jobs','job vacancy'],
      description: 'Open positions. Start your career at willhaben, Njuškalo.hr, bolha.com or MojeDelo.com.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
