<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .digital-marketplaces-karriere {
    position: relative;
    .img-logo-overview {
      border-bottom:1px solid #000;
      border-top:1px solid #000;
      margin-top:30px;
      img {
        max-width:500px;
        margin:50px auto 40px auto;
        display:block;
        @media(max-width:$width-sm) {
          max-width:80%;
        }
      }
    }
 
    .karriere-outer {
      padding-bottom: 30px;
      padding-top: 30px;
      .img-logo {
        max-width:300px;
        margin: 0 auto 40px auto;
        display:block;
        @media(max-width:$width-lg) {
          margin:40px auto;
        }
      }
    }
    .btn-std {
      text-align: center !important;
      margin-top: 30px;
    }
    
    
  
  } 
}
</style>

<template>
  <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild.jpg')})` }" headerImageText="Styria Digital Marketplaces" />-->
  <SocialMediaIconBox />
  <div class="digital-marketplaces-karriere">
    <div class="container-fluid  img-logo-overview">
      <div class="row padding-tb-s">
        <div class="col-lg-12">
          <img src="@/assets/img/marken/brands/styria-marketplaces.svg" alt="Marketplaces" class="img-fluid" />
        </div>
      </div>
    </div>
   
    <div class="container">
      
      <div class="row header-section-box justify-content-center align-self-center padding-t-s">
        <div class="col-lg-3 offset-lg-2 col-md-4 align-self-center">
          <div class="headline-section">{{$t('marketplaces-karriere.marketplaces')}}</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1>{{$t('General.karriere')}}</h1>
        </div>
      </div>
      <div class="row karriere-outer justify-content-center">
        <div class="col-lg-5 align-self-center">
          <img src="@/assets/img/marketplaces/karriere-willhaben.jpg" alt="Willhaben" class="img-fluid shadow" /> 
        </div>
        <div class="col-lg-5 offset-lg-1 justify-content-center align-self-center">
          <img src="@/assets/img/marken/brands/willhaben.svg" alt="Willhaben" class="img-fluid img-logo" /> 
          <div class="btn-std">
            <a href="https://jobsbei.willhaben.at/" target="_blank">
              <span class="txt">{{$t('marketplaces-karriere.btn-txt')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></a>
          </div>
        </div>
      </div>
      <div class="row karriere-outer justify-content-center">
        <div class="col-lg-5 offset-lg-1 align-self-center order-lg-2">
          <img src="@/assets/img/marketplaces/karriere-njuskalo.jpg" alt="Njuskalo" class="img-fluid shadow" />
        </div>
        <div class="col-lg-5  justify-content-center align-self-center">
          <img src="@/assets/img/marken/brands/njuskalo.svg" alt="Njuskalo" class="img-fluid img-logo" />
          <div class="btn-std">
            <a href="https://www.njuskalo.hr/index.php?ctl=help&section_id=10" target="_blank">
              <span class="txt">{{$t('marketplaces-karriere.btn-txt')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></a>
          </div>
        </div>
      </div>
      <div class="row karriere-outer justify-content-center">
        <div class="col-lg-5 align-self-center">
          <img src="@/assets/img/marketplaces/karriere-bolha.jpg" alt="bohla.com" class="img-fluid shadow" />
        </div>
        <div class="col-lg-5 offset-lg-1 justify-content-center align-self-center">
          <img src="@/assets/img/marken/brands/bolha.svg" alt="bolha.com" class="img-fluid img-logo" />
          <div class="btn-std">
            <a href="https://www.bolha.com/joinourpack/" target="_blank">
              <span class="txt">{{$t('marketplaces-karriere.btn-txt')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></a>
          </div>
        </div>
      </div>
      <div class="row karriere-outer justify-content-center">
        <div class="col-lg-5 offset-lg-1 align-self-center order-lg-2">
          <img src="@/assets/img/marketplaces/karriere-mojedelo.jpg" alt="Mojedelo" class="img-fluid shadow" />
        </div>
        <div class="col-lg-5  justify-content-center align-self-center">
                    <img src="@/assets/img/marken/brands/mojedelo.svg" alt="Moje Delo" class="img-fluid img-logo" />
          <div class="btn-std">
            <a href="https://www.mojedelo.com/podjetje/moje-delo-doo-16" target="_blank">
              <span class="txt">{{$t('marketplaces-karriere.btn-txt')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></a>
          </div>
        </div>
      </div>
      <div class="row karriere-outer justify-content-center">
        <div class="col-lg-5 align-self-center">
          <img src="@/assets/img/marketplaces/karriere-trikoder.jpg" alt="Trikoder" class="img-fluid shadow" />
          
        </div>
        <div class="col-lg-5 offset-lg-1 justify-content-center align-self-center">
          <img src="@/assets/img/marken/brands/trikoder.svg" alt="Trikoder" class="img-fluid img-logo" />
          
         
          <div class="btn-std">
            <a href="https://www.trikoder.net/" target="_blank">
              <span class="txt">{{$t('marketplaces-karriere.btn-txt')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></a>
          </div>
        </div>
      </div>
      <div class="row margin-t-m">
        <div class="col-12">
          <div class="btn-std">
            <router-link :to="{ name: 'marketplaces-uebersicht' }"
              ><span class="txt">{{$t('General.back-to-marketplaces-overview')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
   meta: {
    de: {
      title: 'Karriere | Styria Media Group',
      keywords: ['marketplaces','karriere','jobs','stellenausschreibung'],
      description: 'Offene Stellen in der STYRIA. Starten Sie Ihre Karriere bei willhaben, Njuškalo.hr, bolha.com oder MojeDelo.com. ',
      image: require('@/assets/img/og/brands.png'),
      
    },
    en: {
      title: 'career | Styria Media Group',
     keywords: ['marketplaces','career','jobs','job vacancy'],
      description: 'Open positions. Start your career at willhaben, Njuškalo.hr, bolha.com or MojeDelo.com.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
